import { AlertType, HandDominanceDisplay, MaintenanceCycle } from '~/types';
import { CosmicIcons } from '~/utils/ui/CosmicIcons';
import { DB_DEFAULT } from '../constants';
import { buildIconAsString, buildIconWithLabelAsString } from '../ui/buildIconAsString';
export const alertableStatusDisplay = (statusItem, value = null) => {
    if (!statusItem || (!statusItem.requirementText && !statusItem.description)) {
        return value ?? '-';
    }
    const alertType = statusItem.alertType;
    const currentValue = value ?? statusItem.value?.toString() ?? '-';
    if (!alertType) {
        return currentValue;
    }
    // ERROR means the value couldn't be computed. Show an error icon.
    if (alertType === AlertType.ERROR) {
        const alertText = statusItem.errorText ?? '';
        return buildIconWithLabelAsString({
            iconClass: CosmicIcons.error,
            title: alertText,
            color: 'error',
            label: alertText
        });
        // WARNING means it's nearing its requirement. Use yellow/orange warning text.
    }
    else if (alertType === AlertType.WARNING) {
        let alertText = statusItem.warningText;
        const requirementText = statusItem.requirementText;
        if (statusItem.description === 'Date of last performed Checkout or GN2 Check' ||
            statusItem.description === 'Date of last performed SAFER Checkout') {
            alertText = `${alertText}&#013${requirementText}`;
        }
        const icon = buildIconAsString({
            title: alertText ?? 'Warning',
            iconClass: `${CosmicIcons.warning} text--darken-4 fa-sm alert-status`,
            color: 'warning',
            style: 'font-size: 24px !important; width: 24px !important; text-align: center;'
        });
        return `<div class="icon-with-text"  style="display: flex; align-items: center">${icon} <span title="${alertText}" tooltip="${alertText}" class="pl-2">${currentValue}</span></div> `;
        // ALERT means it has matched or passed its requirement. Use red alert text.
    }
    else if (alertType === AlertType.ALERT) {
        let alertText = statusItem.requirementText || statusItem.description;
        if (statusItem.maintenanceCycle === MaintenanceCycle.SAFER_TM_CHECK_OUT) {
            alertText = `${alertText}&#013Required within 60 days of an EVA`;
        }
        const icon = buildIconAsString({
            title: alertText ?? 'Alert',
            iconClass: `${CosmicIcons.error} text--darken-2 fa-sm alert-status`,
            color: 'error',
            style: 'font-size: 24px !important; width: 24px !important; text-align: center;'
        });
        return `<div class="icon-with-text"  style="display: flex; align-items: center">${icon} <span title="${alertText}" tooltip="${alertText}" class="pl-2">${currentValue}</span> </div>`;
        // INFO means this value is acceptable. Just add a title if there's a requirement
    }
    else if (alertType === AlertType.INFO && (statusItem.requirementText || statusItem.description)) {
        const alertText = statusItem.requirementText || statusItem.description;
        return `<span class="alert-status__none" title="${alertText}" tooltip="${alertText}">${currentValue}</span>`;
    }
    // if nothing else, just send back the value
    return currentValue;
};
export const numberDisplay = (input, significantDigits = null) => {
    if (input === null || input === undefined) {
        return '0';
    }
    if (typeof input === 'string') {
        input = parseInt(input);
    }
    if (significantDigits !== null) {
        return (Math.round(input * 100) / 100).toFixed(significantDigits);
    }
    return new Intl.NumberFormat('en-US').format(input);
};
export const asBuiltNumberDisplay = (asBuilt) => {
    return asBuilt ? (asBuilt === DB_DEFAULT ? '-' : asBuilt) : '-';
};
export const booleanDisplay = (bool) => {
    if (bool === null || bool === undefined) {
        return '<i class="fa-2x fad fa-minus-circle"></i>';
    }
    return bool
        ? '<i class=" fa-2x fad fa-check-circle success--text"></i>'
        : '<i class=" fa-2x fas fa-ban secondary--text"></i>';
};
export const serialNumberDisplay = (sn) => {
    return sn ? (sn === DB_DEFAULT ? '-' : sn) : '-';
};
export const sideDisplay = (side) => {
    return side && side !== 'NONE' ? HandDominanceDisplay.get(side) || '-' : '-';
};
export const sizeDisplay = (size) => {
    return size ? (size === DB_DEFAULT ? '-' : size) : '-';
};
export const currencyDisplay = (num, defaultReturnValue = '-') => {
    if (num === null || num === undefined) {
        return defaultReturnValue;
    }
    if (typeof num === 'string') {
        num = parseFloat(num);
    }
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
export const transformHtmlLineBreak = (string) => {
    return string.replace(/<br\s*[/]?>/gi, '\n');
};
export const stringListDisplay = (stringList) => {
    if (!stringList?.length) {
        return '-';
    }
    const items = stringList.map((value) => `<li>${value}</li>`);
    return `<ul class="generic-list">${items.join('')}</ul>`;
};
/**
 * Displays a numerical value with a percent sign.
 *
 * @param value - the number to display
 * @param fixedPlaces - the number of decimal places. Defaults to null, which is
 * no decimal places
 * @param timesOneHundred - if you are working with a value between zero and
 * one, pass true to have it multiplied by a hundred.
 * @returns a string percentage value like "85%" or "-" if no value is provided.
 */
export const percentageDisplay = (value, fixedPlaces = null, timesOneHundred = false) => {
    if (value === null || value === undefined) {
        return '-';
    }
    let numberVal = parseFloat(value.toString());
    // if the value needs to be converted from a 0 to 1 format
    if (timesOneHundred) {
        numberVal *= 100;
    }
    // convert value to string
    let strVal = numberVal.toString();
    // fix it to n decimal places if required
    if (strVal.includes('.') || fixedPlaces !== null) {
        const places = fixedPlaces ?? 2;
        strVal = numberVal.toFixed(places);
    }
    return `${strVal}%`;
};
