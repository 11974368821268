<template><section><h1>Change Log</h1>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.3.2...v5.3.3">5.3.3</a> (2024-11-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>METOX:</strong> rolling back metox requirements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/fe6cb5cca37e5346bc07feaf843a26052d6de4f5">fe6cb5c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.3.1...v5.3.2">5.3.2</a> (2024-11-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>METOX:</strong> row color based on alerts (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/48563f7094a76c404b158d8e37e79ec4c85771b3">48563f7</a>)</li>
<li><strong>METOX:</strong> updated alert above table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/cbeee098c8773650ca698c8fe7c16016ca9f9f0e">cbeee09</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.3.0...v5.3.1">5.3.1</a> (2024-11-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU:</strong> ignore SOP checkout due date for row level alert (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/9544e8b9e437c8ddc7c60dcb2312072b468dcc1e">9544e8b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.2.3...v5.3.0">5.3.0</a> (2024-11-05)</h1>
<h3>Features</h3>
<ul>
<li><strong>Visual alert improvements:</strong> adding alerts with status fields for each hardware type (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/034d2b3cf62a8e67e0a10214f0482e5b0c8fb16e">034d2b3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.2.2...v5.2.3">5.2.3</a> (2024-10-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> added “Crewmember Assigned” to known display words list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/b68c3eb36e0900dab49ecc688adacc0541282f0b">b68c3eb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.2.1...v5.2.2">5.2.2</a> (2024-10-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>HAB/HAP-E:</strong> added crewmember assigned to hardware table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/90f9b76cf3a7ef209bceb508cbe4e6fb34948a6a">90f9b76</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.2.0...v5.2.1">5.2.1</a> (2024-09-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>HAB/HAP-E:</strong> added missing value to activity table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/ce5668fa2dbab39a5ebf9f3545f9a7b425fc6abe">ce5668f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.1.1...v5.2.0">5.2.0</a> (2024-09-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Internal update:</strong> updates to the latest versions of dependencies (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/548457a80ba932af29d5649fa7e28afc822b01d5">548457a</a>)</li>
<li><strong>Visual:</strong> update expansion row click to remove a double border situation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/28692c484a0a02be4d5eb7006f83e09891585f7e">28692c4</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>New hardware:</strong> added HAB / HAP-E (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/3e5734bda7e4f938b2fc8c557df79d1340a9120b">3e5734b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.1.0...v5.1.1">5.1.1</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU Manage:</strong> added EMU Annual Water Sample to activity create for EMU (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/85be95a1b02f3523181f9f189ab82e470d114674">85be95a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.21...v5.1.0">5.1.0</a> (2024-07-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>OOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/7b72f98c85ef054586382e6bd1d6f0cee6d3b18b">7b72f98</a>)</li>
<li><strong>Water Sample:</strong> Add Annual Water Sample to EMU forms, AHD, reports and activities. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/d29fd333c66de99441c2483ffff0d1cbb8646a6f">d29fd33</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Static resources:</strong> changed to prefer a local copy over hitting the network (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/e124c2aee653eaf97c05710361cf4d847d407fc9">e124c2a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.20...v5.0.21">5.0.21</a> (2024-05-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ActivityModal:</strong> Add additional checks for parent and child hardware properties to fix modals not opening. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/0f6b4fa0882d2b7c980540c77c35ea9a3f2d4319">0f6b4fa</a>)</li>
<li><strong>ci:</strong> token for semantic-release (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/1e9929d9fe304773a6233d0eb53ad1ee65fdfd77">1e9929d</a>)</li>
<li><strong>FEMUR:</strong> Add new xls table for EMU comments. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/72694798cefde6914f163e8be5978f0cbf2c8a86">7269479</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.19...v5.0.20">5.0.20</a> (2024-05-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ActivityModal:</strong> Create activities for EMUs and HUTs together. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/94a63f3caba50e15258b0f89e8a927ba29f5415d">94a63f3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.18...v5.0.19">5.0.19</a> (2024-05-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU:</strong> Remove size select. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/f7c99e673500095f492060b379dda469d6a1035b">f7c99e6</a>)</li>
<li><strong>FEMUR:</strong> Update endpoint. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/263b82ea1d5b869e8dd72f5747f5a42e7892e910">263b82e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.17...v5.0.18">5.0.18</a> (2024-05-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li>correct DEPLOYMENT_IMAGE_NAME (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/dae675303473315def349fafe5a9361ccee56341">dae6753</a>)</li>
<li>Dockerfile artifact registry project (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/fff74ce78769e3e509b48ffc66f856e997ee42f6">fff74ce</a>)</li>
<li><strong>EMU and HUT manage pages:</strong> Add size and installed on columns. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/1ba0fcd641bede029f23f15037dd50a4f82d1d5b">1ba0fcd</a>)</li>
<li><strong>Footer:</strong> updated accessibility link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/9faf6bb08aa407b8fe8a38eb30aaac626a406660">9faf6bb</a>)</li>
<li><strong>HardwareManage:</strong> Add meta row click. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/36cde40c052d915d818443e3ec9360a6d96a6f43">36cde40</a>)</li>
<li><strong>Metox Simulator:</strong> filter checkout due 1095d (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/e32ff1ce9f8d964185082b7763f875619bdcd0f4">e32ff1c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.16...v5.0.17">5.0.17</a> (2024-02-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware:</strong> fix Archive/Unarchive button (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/82c84af3b1a14110fd0e81213e0b35605150e762">82c84af</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.15...v5.0.16">5.0.16</a> (2024-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>nOOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/4c8047cd4de3bd1c9c990bb326dcd0d74db891a0">4c8047c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.14...v5.0.15">5.0.15</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AHD:</strong> corrects bug where the AHD wasnt updating properly (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/1fcc1bb8c247579bbe85a66b3a0436a2c41e24f3">1fcc1bb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.13...v5.0.14">5.0.14</a> (2024-01-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> Update Iodinate Loop Scrub to be Loop Scrub w/Iodination. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/fbd8a0b35afe2049e36b1665bc432265d62d94b3">fbd8a0b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.12...v5.0.13">5.0.13</a> (2024-01-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ActivityAlerts:</strong> Fix to use v-safe-html to render subscript. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/358334bbe0bc57e150a70c51441e9b54f20d9757">358334b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.11...v5.0.12">5.0.12</a> (2023-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AreYouSureDialog:</strong> Fix dialog showing and typo. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/95446e0171ae3ad9114e2765b18234b586bb5318">95446e0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.10...v5.0.11">5.0.11</a> (2023-12-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/c25fbdf34aeaac6ab79c915e8cac3e00982db316">c25fbdf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.9...v5.0.10">5.0.10</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li>correct env check (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/865abbfa2ccf4151d2c7c93fdf2c1cb092168258">865abbf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.8...v5.0.9">5.0.9</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li>correct env var check (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/0a0d596244c3fa8bb0245086139e8c9b36b4a4fb">0a0d596</a>)</li>
<li>correct url in staging (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/d3322cb9dc314a0acb793ef094cec3a9407590ad">d3322cb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.7...v5.0.8">5.0.8</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ci:</strong> correct NODE_ENV (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/e1f58b618364ca1db507b0800972524f7a86a449">e1f58b6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.6...v5.0.7">5.0.7</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ChangeLog:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/8e1084a096e9a2aaedac0b3e143fb971e75ff516">8e1084a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.5...v5.0.6">5.0.6</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> update allows for developers to see logs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/86d86d5ee87920c3d0d4aae6c7b895f4975c105f">86d86d5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.4...v5.0.5">5.0.5</a> (2023-12-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>OOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/5ef3c1bbedd8d5735c6f6b115c46fef0282f5003">5ef3c1b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.3...v5.0.4">5.0.4</a> (2023-12-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>OOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/1cd90809a6e5b17cb818804b8da1cd43f51d4334">1cd9080</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.2...v5.0.3">5.0.3</a> (2023-12-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>OOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/c084a089ef14d01ed0c14157e4aab810b485e82a">c084a08</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.1...v5.0.2">5.0.2</a> (2023-12-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/92e4a3bcf70ab7236da923a4eb87bbb9f34dc190">92e4a3b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v5.0.0...v5.0.1">5.0.1</a> (2023-12-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>OOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/537b548ed70854941ba05aa3d47aa0b48f6e7cf4">537b548</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.19.1...v5.0.0">5.0.0</a> (2023-12-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>Stage Separation:</strong> Separating concerns from contract specific requirements. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/949befc6d41b46ab18be140d7ae12ae0f6476e25">949befc</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Stage Separation:</strong> divorce</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.19.0...v4.19.1">4.19.1</a> (2023-11-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EFSF:</strong> Added attribute for logging pounds of water to activities logged against EFSF. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/dc4001ad2980b5617b9f65f776c3f6e0917e0155">dc4001a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.6...v4.19.0">4.19.0</a> (2023-11-16)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activities:</strong> New way to view Activities (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/df9ae5d45f9c946566695ee5268347f4e7c3ce16">df9ae5d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.5...v4.18.6">4.18.6</a> (2023-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Confirmation Dialogs:</strong> Updated close event listener so that are you sure dialog closes on click of no. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/cd7c94b929e9bbf7cbbe73b136239be4970a9c50">cd7c94b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.4...v4.18.5">4.18.5</a> (2023-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/c06528b605c4391180c576ba8a1ae683d42de1da">c06528b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.3...v4.18.4">4.18.4</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheFooter:</strong> Add back changelog version. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/73a62bee53f40baa30b519f041e6b91b3012dde9">73a62be</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.2...v4.18.3">4.18.3</a> (2023-11-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Metox Regeneration:</strong> Removed the METOX Regeneration Due Date from the displays; Last METOX Regeneration date is no longer red; (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/1b4037acf11c102e0fc3758a4d5c1527069abcd2">1b4037a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.1...v4.18.2">4.18.2</a> (2023-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ci:</strong> whitespace (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/2a9b71b3e3880ee83e6abdfadcbcd3bab7abe8fe">2a9b71b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.18.0...v4.18.1">4.18.1</a> (2023-10-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deps:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/09f41b9562ccdb2de85999e0ada9dd8cb1de7b65">09f41b9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.17.1...v4.18.0">4.18.0</a> (2023-10-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>UI:</strong> improved tooltips and navbar layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/ce70bd75ae795670210c90407fb19a6ffa4b3c6e">ce70bd7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.17.0...v4.17.1">4.17.1</a> (2023-10-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> Update max METOX cycles from 22 to 70. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/bca9c366184036e53078b51f640d5be7a82458ef">bca9c36</a>)</li>
<li><strong>Footer:</strong> streamlined layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/f177fc04b27bafd49cba7a81070c34abd6932e51">f177fc0</a>)</li>
<li><strong>LREBA:</strong> Expiry of LREBA and LPGT from 7 yrs to 9 yrs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/ad2abb661748aa1c3b27a8c4dcf9de8081ce54cd">ad2abb6</a>)</li>
<li><strong>METOX:</strong> Update requirements for OOT (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/c9f5c0e3310d5a136474a0cb69f89b503265ec10">c9f5c0e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.16.2...v4.17.0">4.17.0</a> (2023-10-26)</h1>
<h3>Features</h3>
<ul>
<li><strong>CI:</strong> Promote new CI/CD process (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/975dea073ad5707450516b3f5e296e11086b37d4">975dea0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.16.1...v4.16.2">4.16.2</a> (2023-10-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware Activity Types:</strong> Added EMU Feedwater Supply Filter to EMU H20 Recharge hardware activity types. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/7d029c328a9fc0b3d7fb18674feb5c8753938cce">7d029c3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.16.0...v4.16.1">4.16.1</a> (2023-08-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>nOOT:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/dab0983d7e7783187ff1bde35884a9d47dda48e4">dab0983</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.15.1...v4.16.0">4.16.0</a> (2023-07-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>SAFER:</strong> Implemented updates from feedback. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/0cd6e1205e888b1d787f69d923dd1cfa5d4f639c">0cd6e12</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.15.0...v4.15.1">4.15.1</a> (2023-07-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Branding:</strong> new tab bar icons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/e83f847676e85a17f97ceb04e4cf5a3a48b8a293">e83f847</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/compare/v4.14.1...v4.15.0">4.15.0</a> (2023-07-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>EMU:</strong> Show installed HUT on AHD (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/oot/frontend/commit/bf8d1b6ee20ae176a752666bb712372f6884e087">bf8d1b6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/compare/v4.14.0...v4.14.1">4.14.1</a> (2023-04-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>SAFER:</strong> update to handle safer property name change (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/2d32c92bdeb9a42a4211c68426ed80d3c0bdd1c7">2d32c92</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/compare/v4.13.0...v4.14.0">4.14.0</a> (2023-04-19)</h1>
<h3>Features</h3>
<ul>
<li><strong>Scroll Behavior:</strong> prevent default scrollwheel behavior on number inputs (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/ad2088d80a4c2ec3f7eab98a09aea5ca573065c1">ad2088d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/compare/v4.12.0...v4.13.0">4.13.0</a> (2023-04-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>SAFER:</strong> Add new SAFER maintenance requirements to displays (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/2f6d0accc61230b658551e5e7e0474bf565e63a6">2f6d0ac</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/compare/v4.11.1...v4.12.0">4.12.0</a> (2023-04-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>METOX:</strong> Update femur header to reflect new metox max cycles (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/5842013501601883f68f2bfebf0ad092a7d87211">5842013</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/compare/v4.10.2...v4.11.0">4.11.0</a> (2023-03-31)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>semantic release:</strong> Fix semantic release step by updating node version (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/8a49bb949f52f104f6758b2e655f79d3f0398b11">8a49bb9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>EMU Battery LLB:</strong> Change column from 5 year expiration to 7 year expiration (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/bfe8698df2c90328e1e851cc806804a83068c21d">bfe8698</a>)</li>
<li><strong>LLB:</strong> Add display mappers for new LLB 2 maintenance types (<a href="https://gitlab.com/mri-technologies/cosmic/oot/frontend/commit/86ddf591ef3e9a32de19dca110ada66a189e8f53">86ddf59</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.11.0...v4.12.0">4.12.0</a> (2023-02-16)</h1>
<h3>Features</h3>
<ul>
<li><strong>LLB:</strong> Add display mappers for new LLB 2 maintenance types (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/86ddf591ef3e9a32de19dca110ada66a189e8f53">86ddf59</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.10.0...v4.11.0">4.11.0</a> (2023-02-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>semantic release:</strong> Fix semantic release step by updating node version (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/8a49bb949f52f104f6758b2e655f79d3f0398b11">8a49bb9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>EMU Battery LLB:</strong> Change column from 5 year expiration to 7 year expiration (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bfe8698df2c90328e1e851cc806804a83068c21d">bfe8698</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.9.0...v4.10.0">4.10.0</a> (2022-09-06)</h1>
<h3>Features</h3>
<ul>
<li><strong>Legacy FPU:</strong> Change legacy FPU return maintenance type label (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/2dff7c8626215a2bcd4a42dfb699803ec9f867db">2dff7c8</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.8.0...v4.9.0">4.9.0</a> (2022-09-02)</h1>
<h3>Features</h3>
<ul>
<li><strong>Annex2:</strong> Add an XLS export option (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d6714c60b658d01b83a119bd93a3c35e628657de">d6714c6</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.7.0...v4.8.0">4.8.0</a> (2022-09-02)</h1>
<h3>Features</h3>
<ul>
<li><strong>Legacy FPU:</strong> Change status to return due date (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6afaef23d62cda05899e3e994cda127a1348d88e">6afaef2</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.6.0...v4.7.0">4.7.0</a> (2022-08-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>dates:</strong> fix date transformation in request interceptor (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6150295a924f810ed010f4e268d6ebff3f5dc2f6">6150295</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Legacy FPU:</strong> Add legacy FPU hardware type (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a164b14318985f5f256f8d2192bf8a93ee37ee2a">a164b14</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.5.1...v4.6.0">4.6.0</a> (2022-08-05)</h1>
<h3>Features</h3>
<ul>
<li><strong>sanitize html:</strong> replace all usage of v-html with v-safe-html (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/72be3c71c44d78a4e952166aca24f01811a831dd">72be3c7</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.5.0...v4.5.1">4.5.1</a> (2022-08-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Dependencies:</strong> updated to vue 2.7, updated imports from cosmic ui (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7f9a1ed9370af7b0631bfeb030948eb13ef9ce2b">7f9a1ed</a>)</li>
<li><strong>styling:</strong> updated deep selector, fixed alignment for hardware display with no alert status (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/8a019152b712575c83ab5ccd189d776b8c41c706">8a01915</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.4.1...v4.5.0">4.5.0</a> (2022-06-15)</h1>
<h3>Features</h3>
<ul>
<li><strong>Logo:</strong> streamlined COSMIC OOT logo (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/3b84a81ed028bc02a733bb381f9cb389e0c316f8">3b84a81</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.4.0...v4.4.1">4.4.1</a> (2022-02-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Help Links:</strong> update Help Links for OOT (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/493bd5d09ddfebfb494db0dab1e8f8bdbb16e804">493bd5d</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.8...v4.4.0">4.4.0</a> (2022-02-09)</h1>
<h3>Features</h3>
<ul>
<li><strong>SAFER:</strong> Show installed parts on Manage page AHD (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/dd585879ee99eb7c3b4915ca632fd0fb0dbb9194">dd58587</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.7...v4.3.8">4.3.8</a> (2022-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU Battery LLB:</strong> Remove duplicate form field for pressurized time (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d8128dfdd9839e028668b6767e2655fd56515fb9">d8128df</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.6...v4.3.7">4.3.7</a> (2022-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU Water Processing Jumper:</strong> Added back in status column to hardware table. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d2581d74cddd1913b811686eb14e74b52b410ba7">d2581d7</a>)</li>
<li><strong>FEMUR:</strong> Fix export bug when there are no safer lpgt batteries in the system (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1df90d6539a8f0f46d1c2683fca4c089d41d606b">1df90d6</a>)</li>
<li><strong>Hardware Tables:</strong> Removed redundant status column. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d5129628b4acb818cebfbd2ed7cd9b2d83e41bb4">d512962</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.5...v4.3.6">4.3.6</a> (2022-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Batteries:</strong> Batteries now have onTime and use EVA_READINESS for activities instead of COMMON_ACTIVITIES to avoid duplicate form fields. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/80e348a15b1da917a53b8524f9014fa6dcb095c3">80e348a</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.4...v4.3.5">4.3.5</a> (2022-02-07)</h2>
<h3>Reverts</h3>
<ul>
<li>Revert &quot;Squashed commit of the following:&quot; (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/afe1884d694000b5cbc1221116d8c6b74893c003">afe1884</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.3...v4.3.4">4.3.4</a> (2022-02-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity:</strong> Fix back button after deleting an activity (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/36b84943bc1d931343709adf6c71ef07099d531d">36b8494</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.2...v4.3.3">4.3.3</a> (2022-02-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>IEU SCU:</strong> Change AHD label from Scrub Due to Water Circulation Due (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c19cce61cf23aee7d8d02156168bab58007b1802">c19cce6</a>)</li>
<li><strong>UIA:</strong> Scrub Due now showing as H2O Circulation Due. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b59245964adb6655c73b796cd23a9e061b248933">b592459</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.1...v4.3.2">4.3.2</a> (2022-02-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AHD:</strong> Only showing uses available on AHD. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bd6b68a824e69aeeb9278b7968001eeb50f0f94d">bd6b68a</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.3.0...v4.3.1">4.3.1</a> (2022-02-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>App Date:</strong> Added margin bottom if rendering an app date. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/629d6e8c646740f970d52b5a5ff37368cbed48e5">629d6e8</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.2.0...v4.3.0">4.3.0</a> (2021-12-20)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activities:</strong> Add link to related activities alert on create modal, and a dismiss button to close the alert (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/150b2ec283aeb948a906b744d284f950ea0b1472">150b2ec</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.1.5...v4.2.0">4.2.0</a> (2021-12-16)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware:</strong> Add activity support and femur table for SAFER LPGT Battery (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e314201af84bcb9ac0f7bc5fa9a2bf49d15dae3a">e314201</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.1.4...v4.1.5">4.1.5</a> (2021-12-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EHIP Light:</strong> added EVA activity type. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9e80fe332703803a767d484520439a82bd9daa5e">9e80fe3</a>)</li>
<li><strong>Tables:</strong> updated styling for chevron on expandable tables (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f0dfa78307329a09d87bae847427ce48b998bfa9">f0dfa78</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.1.3...v4.1.4">4.1.4</a> (2021-12-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> added alert of similar activities. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9d4fa4a726f86795f0b1c2802505690885f6d362">9d4fa4a</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.1.2...v4.1.3">4.1.3</a> (2021-12-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>HL Battery:</strong> added to list of read-only hardware. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4c1804e952b042e43be77da2b33f7102b43848d7">4c1804e</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.1.1...v4.1.2">4.1.2</a> (2021-12-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Dev:</strong> Internal port binding updates (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/8a782573340902acc58d0bc2e57a83ccb0242fd8">8a78257</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.1.0...v4.1.1">4.1.1</a> (2021-12-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> fixes multiple shared UI components (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4b50657ba30202e21238d3d47411052bd6ba83dd">4b50657</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.6...v4.1.0">4.1.0</a> (2021-12-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>OOT Testing:</strong> update including automated testing of various UI areas (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6204812de8dcb871948c6a85695c7120917eb2de">6204812</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>PGT:</strong> Add TAK Data Approval activity (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/dc005357f4c89719226f0b372a197a4d9a6f3ac7">dc00535</a>)</li>
<li><strong>SAFER:</strong> Remove existing input fields from EVA form and replace with Deployed radio button (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/88dfb7534521f5b0a2611b6dcc8a61e970e1048c">88dfb75</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.5...v4.0.6">4.0.6</a> (2021-12-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inputs:</strong> moving inputs to shared (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/469ea1fc8539b1c0aab34f166359b19af8c4f25c">469ea1f</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.4...v4.0.5">4.0.5</a> (2021-12-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR Export:</strong> fix styling issue with EVA Readiness column (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1d9150b77ecb9f4de1ada84fe55aa0b34f493bf1">1d9150b</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.3...v4.0.4">4.0.4</a> (2021-12-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>patch:</strong> security patch (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5231e82a2fa85b7f05e4e40b1dcfbedc5647f7b9">5231e82</a>)</li>
<li><strong>Security:</strong> patch to latest (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1555c682300f2bac4971c6c4547e08007611b487">1555c68</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.2...v4.0.3">4.0.3</a> (2021-12-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware:</strong> Fix table sorting on certain date fields. Update Metox header display (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/438ac7d29e669ed38e7cf31c605510e6efb034a7">438ac7d</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.1...v4.0.2">4.0.2</a> (2021-11-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> Added additional information about the selected report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/10f64b34d4812a00113cc255122f74ca1ea72d29">10f64b3</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v4.0.0...v4.0.1">4.0.1</a> (2021-11-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AppBreadcrumbs:</strong> add Help links to Hardware and Activity module (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bfb012252548f2a7ae86bcac07a36e2b32737957">bfb0122</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.16.3...v4.0.0">4.0.0</a> (2021-11-29)</h1>
<h3>Features</h3>
<ul>
<li><strong>Planned Activities:</strong> this update allows users to plan activities and actualize them when ready (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/250bb156987cfa75b21e792332726602ba66c901">250bb15</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Planned Activities:</strong> Planned Activities released</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.16.2...v3.16.3">3.16.3</a> (2021-11-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> Update link-looking texts to highlight texts (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/423947100ee71459be23d56f4d8e8e7c4df8d5e4">4239471</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.16.1...v3.16.2">3.16.2</a> (2021-11-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Architecture:</strong> improved UX (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/764ca8d99d0d5bda78c4c8a1d06c8f9e83d04f0a">764ca8d</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.16.0...v3.16.1">3.16.1</a> (2021-11-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>System:</strong> improved overall stability of the system (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/defdad4de5df8956f46f13a98b63e8edce9cbfc1">defdad4</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.15.1...v3.16.0">3.16.0</a> (2021-11-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>Reporting:</strong> Add filter for EVA readiness on Item Instance report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/59853f6ddafc7cbf213f267d0e5a33f33747dfad">59853f6</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.15.0...v3.15.1">3.15.1</a> (2021-11-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> Add better labeling on date filters (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/32b2ae09ade2b2fc3fb29351b58293972394f47e">32b2ae0</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.14.2...v3.15.0">3.15.0</a> (2021-11-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware:</strong> Add flight columns to archived table headers (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/efda22cc83c86366a63818848144f9bf1ffc397f">efda22c</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.14.1...v3.14.2">3.14.2</a> (2021-11-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>LREBA:</strong> use standard battery logic to determine LREBA icon color (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bd8515284890089cff343beb94998b28fadb0d6e">bd85152</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.14.0...v3.14.1">3.14.1</a> (2021-10-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> Fix bug preventing adding activity comments from the Hardware manage page (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9d0842049f8157edb382fb214d1ed65692a1c0db">9d08420</a>)</li>
<li><strong>Flights:</strong> Remove flight caching (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/da3661eac3fab1cad61b033efb1641e03fdca17b">da3661e</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.13.2...v3.14.0">3.14.0</a> (2021-10-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware:</strong> Add inspection activity and EVA readiness to all Hardware types (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ff4f8bbc7a0b0fb396f3e93ce69d390209e55607">ff4f8bb</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.13.1...v3.13.2">3.13.2</a> (2021-10-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>o2 poppet:</strong> remove traces of O2 poppet verification on uia and ieu/scu (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/875cd7bcad8855b2aa261df5fcf13696cbe71627">875cd7b</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.13.0...v3.13.1">3.13.1</a> (2021-10-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readonly Hardare:</strong> Fix hiddden AHD for readonly users (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/301cfe9b0709eb657315c489a6cc6704cd4fa4c3">301cfe9</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.12.0...v3.13.0">3.13.0</a> (2021-10-06)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activities:</strong> Add column for comments in the activities tables (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0a2de1c1d395d26d6eb91c0a836a5e1adad81592">0a2de1c</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.11.3...v3.12.0">3.12.0</a> (2021-10-05)</h1>
<h3>Features</h3>
<ul>
<li><strong>Footer:</strong> Add logout button (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/36c52db91c453b043e2011261eb9815561eaa05f">36c52db</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.11.2...v3.11.3">3.11.3</a> (2021-10-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Pending Maintenance:</strong> filter readonly items out of maintenance report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/3c92bdd1eed7c65f49306c25a24aec6496ef8c48">3c92bdd</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.11.1...v3.11.2">3.11.2</a> (2021-10-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth Redirect:</strong> redirect to gatekeeper on first 401 response (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9b5746368b1948d30ab7e2797038eed285746b27">9b57463</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.11.0...v3.11.1">3.11.1</a> (2021-10-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> update deployment options (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d2b3167e2356d24ce4aaa1e649aad388ba91483c">d2b3167</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.10.5...v3.11.0">3.11.0</a> (2021-09-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Cycle display:</strong> updated and correctly display logic for Battery cycles (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6fb4a9975b8d92055cd1a6ba5d8b27911a0e9e6f">6fb4a99</a>)</li>
<li><strong>Deps:</strong> update application dependancies (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4137e10637fce4d6a6041e7b792d10dc6aaf6564">4137e10</a>)</li>
<li><strong>Read Only Views:</strong> Improves the layout and ui of the readonly pages (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f87c3ef0f9c1077eeb16bf242955ec6e99f996e7">f87c3ef</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Hardware &amp; Activities:</strong> Redirect readonly users to appropriate views (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/774ffd8f646cd9e4dbd150e7ed3822c570a5c620">774ffd8</a>)</li>
<li><strong>Hardware/Comment Tables:</strong> Add better default sorting (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f1f4e1790f7eb3dd241e8950254f448175848fb3">f1f4e17</a>)</li>
<li><strong>Increments Report:</strong> Add start and end date filters (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/52cbe87289125155f2a492fe7d178845558009c7">52cbe87</a>)</li>
<li><strong>Pending Maintenance:</strong> Add non-date related items to report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/adf822e366e4ebfd9182cc063a256a2056aa15de">adf822e</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.10.4...v3.10.5">3.10.5</a> (2021-09-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU Battery LLB:</strong> Add missing form fields for After Charge (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/27ad09a13f8ba4d01a5869b49c3ae3cd1e366344">27ad09a</a>)</li>
<li><strong>Links:</strong> Point links at correct hardware list URL and remove anchor tags where possible (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f4cb0d0f2e9030473f79ef9d3746bd7b5eabdddb">f4cb0d0</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.10.3...v3.10.4">3.10.4</a> (2021-09-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ac12f901063c7fba24230977570fa031e0eff7aa">ac12f90</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.10.2...v3.10.3">3.10.3</a> (2021-09-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>METOX SIM:</strong> add annual changeout as an activity type (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/961c976c4c96c72c1ab23e660838d4dbf3780aa1">961c976</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.10.1...v3.10.2">3.10.2</a> (2021-09-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Css:</strong> nested table rows are now the correct color (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9560051522104f7b62e7a7231ce81363d5653079">9560051</a>)</li>
<li><strong>Side Nav:</strong> corrected UI issue (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b05e12df0e7368a09f64c74bc50c1e05580b4cd0">b05e12d</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.10.0...v3.10.1">3.10.1</a> (2021-09-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Status:</strong> update where to look for status icon (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4904e172943e2418705054ad56d8ed2f79ecadf4">4904e17</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.9.0...v3.10.0">3.10.0</a> (2021-09-14)</h1>
<h3>Features</h3>
<ul>
<li><strong>Authentication:</strong> Change detects if the user is logged in before loading the application to prevent viewing unauthorized data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0e99b489f3a1dff476903f75e40b09b44275e016">0e99b48</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.8...v3.9.0">3.9.0</a> (2021-09-14)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Update corrects potential memory leak (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/538e52f12f04484e1a79fe6946ee365ae603de36">538e52f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Activity:</strong> Improved UX around comments and their tie to Hardware and Activities (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/87f5979893455327ecd2770cb59a05837f2a81ec">87f5979</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.7...v3.8.8">3.8.8</a> (2021-09-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> Improved save feature (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b986ac13a94a42170cb0b748410cf6e14f064c02">b986ac1</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.6...v3.8.7">3.8.7</a> (2021-09-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity Forms:</strong> updating header to Activity from Information (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7b7ff1b428f905a66d3569779bbee705d4202cff">7b7ff1b</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.5...v3.8.6">3.8.6</a> (2021-09-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Read Only Activity:</strong> corrects errors and displays more information (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/67f681a2f31e2b429f4432b2461070ac4d423496">67f681a</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.4...v3.8.5">3.8.5</a> (2021-09-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity AHD:</strong> Fetch all hardware details when expanding readonly table row (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b7e9cf31f38bf490732b8eeaaaf3df4545035a37">b7e9cf3</a>)</li>
<li><strong>AHD:</strong> corrects issue using the wrong data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e330c8fdd93a4cd9a91d279d50417322abaa4b39">e330c8f</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.3...v3.8.4">3.8.4</a> (2021-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Excel Export:</strong> Output cleanup (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/be5dc8d81a772efe36641f6a7638074a15956330">be5dc8d</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.2...v3.8.3">3.8.3</a> (2021-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Pending Maintenance:</strong> show the sort direction (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/fb3dc7886f68542cd0edab170f8e7321ba5057f6">fb3dc78</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.1...v3.8.2">3.8.2</a> (2021-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>LPGT Battery:</strong> Round timespan (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/79fcdbcf09fc7858239e094f8d3910ca7bf1f0bd">79fcdbc</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.8.0...v3.8.1">3.8.1</a> (2021-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU:</strong> removal of &quot;description&quot; from activity form (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d2d11f31ff71ead530c72dcbb66a943e54449611">d2d11f3</a>)</li>
<li><strong>EMU Activity:</strong> dont show raw html in label (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6bcf24ea6ad06f1933d6547e5eba1f609714ec9c">6bcf24e</a>)</li>
<li><strong>HL Battery:</strong> label adjustment (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ee5d25c0abbb17f1eac6f1a8806360f3b165eebf">ee5d25c</a>)</li>
<li><strong>HUT ORU:</strong> update fixes empty orange box where there should have been alert info (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/684404adac6ae87f303abbd6b5db644fceaca67b">684404a</a>)</li>
<li><strong>OOT:</strong> add breadcrumbs to reports and OOT in general (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b638fc13ddc6a701412ece0c0a91b2764ff3504a">b638fc1</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.7.4...v3.8.0">3.8.0</a> (2021-09-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>FEMU-R-3:</strong> update includes an excel export (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/03ae28b4d14f4468f055eac3ea19d701ad695f1c">03ae28b</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.7.3...v3.7.4">3.7.4</a> (2021-09-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Table:</strong> update to remove html (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b4c98a182d7adbe388a819888bb5214536e12792">b4c98a1</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.7.2...v3.7.3">3.7.3</a> (2021-09-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/acbd74e67f0bc6b942c07e17f3ad07637f0081c7">acbd74e</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.7.1...v3.7.2">3.7.2</a> (2021-09-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU:</strong> change last actual date columns to due dates on EMU (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/62df8cbaca45f84c0c8a20a6a8fdeb1c855fd68f">62df8cb</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.7.0...v3.7.1">3.7.1</a> (2021-09-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> corrected an issue on some activities preventing saving of attributes (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/99a28fab4009a2ba75b3888e664f849573f1f0bf">99a28fa</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.6.0...v3.7.0">3.7.0</a> (2021-09-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware Display:</strong> update provides vastly more insight into hardware at a glance (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/510f2faf4e8ab96dbf9b46988409b7a4d94e7587">510f2fa</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.5.0...v3.6.0">3.6.0</a> (2021-09-02)</h1>
<h3>Features</h3>
<ul>
<li><strong>Maint. Report:</strong> Include past due items when filtering and add better column widths (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e2868ea35311533fa45da8bf53b97ba275da12aa">e2868ea</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.4.3...v3.5.0">3.5.0</a> (2021-09-01)</h1>
<h3>Features</h3>
<ul>
<li><strong>User:</strong> Create profile page (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/41281cdafe3bc129c40704b5ebeae943df9c9525">41281cd</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.4.2...v3.4.3">3.4.3</a> (2021-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> added emphasis to values that are in a warning/alert state (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e627093c1dc07933b704c163558f4dc3b21271fe">e627093</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.4.1...v3.4.2">3.4.2</a> (2021-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>IEU/SCU:</strong> update display (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/28c650c2472608b2e95d658ddc21cf97c56a467c">28c650c</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.4.0...v3.4.1">3.4.1</a> (2021-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Service worker:</strong> version bump (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7c5f5bc06f80e6a4bee114272588435c895ee7ec">7c5f5bc</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.3.3...v3.4.0">3.4.0</a> (2021-08-31)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Date:</strong> removing the word Date from ui (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a90dda5618c39432ac860779adc4e8a54ba7e50a">a90dda5</a>)</li>
<li><strong>Reporting:</strong> added AM/PM to report footers (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/89a64606fddbac119bf7c7ba3b6cb09457307999">89a6460</a>)</li>
<li><strong>Select:</strong> corrected bug (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e6761748ab67fa675df81973306fe1ff9a3bcef5">e676174</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Hardware Forms:</strong> added size dropdown to EMU and HUT ORU (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/8924b57dca38eaf3aace1b8348df9f213470b96e">8924b57</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.3.2...v3.3.3">3.3.3</a> (2021-08-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Table:</strong> update for DATETIME sorting (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/34a01450b5d7b3ed23046592894d494bfe2df4a3">34a0145</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.3.1...v3.3.2">3.3.2</a> (2021-08-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Authorization:</strong> Check for 403/401 response (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6a04ed9e4e2e735da06a3b72d1828af6ad8054d0">6a04ed9</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.3.0...v3.3.1">3.3.1</a> (2021-08-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EVA:</strong> remove Has Item Instances filter (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/357576e5ce61e9bb5cf6e20cffb2a343a640e203">357576e</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.2.2...v3.3.0">3.3.0</a> (2021-08-30)</h1>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> correct Authorization loop implemented (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/dee94e29b0b96b266396111a8e57a74b59b0f784">dee94e2</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.2.1...v3.2.2">3.2.2</a> (2021-08-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> corrects various ui issues (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/2f0a027177871171c69f061117d82c6d87086f41">2f0a027</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.2.0...v3.2.1">3.2.1</a> (2021-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Flight Edit:</strong> form doesn't bind to correct date properties (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/68a428d685fff48aa26a9b02bb1bc72b22a33f7d">68a428d</a>)</li>
<li><strong>Schema:</strong> update corrects fps and flight info (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b744ef7d1df1e3bc71824cc6beea0c18e3ba6a3f">b744ef7</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.1.0...v3.2.0">3.2.0</a> (2021-08-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>OOT:</strong> added threshold based styling (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/999e1bc63b3798cf5e23b04beac1eae3f853ffe9">999e1bc</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.0.4...v3.1.0">3.1.0</a> (2021-08-26)</h1>
<h3>Features</h3>
<ul>
<li><strong>FEMU-R-003:</strong> add ability to export report as PDF (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/cfa4576c8f9380024614d4ee35fd876a79608583">cfa4576</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.0.3...v3.0.4">3.0.4</a> (2021-08-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to latest (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/163193c4263ef69b0a2fa1069cb2ffa4f9bc65a4">163193c</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.0.2...v3.0.3">3.0.3</a> (2021-08-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AHD:</strong> update corrects flightUp/Down formatting (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ab6d20dda32033d770ca8c8bfc8ea4bf037edd40">ab6d20d</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.0.1...v3.0.2">3.0.2</a> (2021-08-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> update correct non-displaying icon (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ae6ca459c1c5925e52b8db17ba69674810e4cd9b">ae6ca45</a>)</li>
<li><strong>Table:</strong> style updates (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5bc8d95e1ebd3df9ea5e896b36f9ba33a4fbdf05">5bc8d95</a>)</li>
<li><strong>Table:</strong> update adds zebra-striping (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c3a85bd938d6c2700ca1f38fe3e744aadf089ce9">c3a85bd</a>)</li>
<li><strong>Table:</strong> update fixes the Last EVA in series column (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b455afc5cc879705093849c50bd6da6eb62b8e70">b455afc</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v3.0.0...v3.0.1">3.0.1</a> (2021-08-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AppTableExpandable:</strong> update corrects undefined css (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e6e6e6b0469a891c1edf1f289efaa62712cc83d3">e6e6e6b</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.16.3...v3.0.0">3.0.0</a> (2021-08-25)</h1>
<h3>Features</h3>
<ul>
<li><strong>schema:</strong> updates to 5.1.0 (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/41518ab160dc93133d4068dc1f9108be784861d9">41518ab</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>schema:</strong></li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.16.2...v2.16.3">2.16.3</a> (2021-08-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> Add context to some date column headers (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/8e40682f59258d457e28854097260a2a424afe21">8e40682</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.16.1...v2.16.2">2.16.2</a> (2021-08-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UX:</strong> update for consistency (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/290f7a2808d6d68c3db1ae7b81e628c929034074">290f7a2</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.16.0...v2.16.1">2.16.1</a> (2021-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> Consolidate columns (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5027720876fa463f92f85f20e5c78603811fc0e5">5027720</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.15.1...v2.16.0">2.16.0</a> (2021-08-23)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> added a splash page to this report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/96e9806b9ce1a62f347c9b02a92dff44aa7638a6">96e9806</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Remove timespan values in favor of hard dates:</strong> update ui to reflect (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/dfaf0ab854da7d03e21cfb718ff5af4da315dbc7">dfaf0ab</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.15.0...v2.15.1">2.15.1</a> (2021-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>E2E:</strong> Update default timeout to 5 seconds and change input selector for Serial (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ea0f9f06fffb8f05a441a724bc711786b6a6c876">ea0f9f0</a>)</li>
<li><strong>Hardware:</strong> enable basic form validation for hardware (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4bd052b03bad298c784ac702024270af6472272d">4bd052b</a>)</li>
<li><strong>On Ground Icon:</strong> update makes icon grey (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e27c3c6fa7053e1a3a4b8ffe3b2515896b9a0218">e27c3c6</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.9...v2.15.0">2.15.0</a> (2021-08-20)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Flights:</strong> correct launch/return ids on hardware flight selection (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4a4d0f15c97307b89fb31ec99ac0023e0489e621">4a4d0f1</a>)</li>
<li><strong>Home:</strong> create activity always present (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f358fb311edfd1ce13354f52836d0c56d375e69b">f358fb3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>E2E:</strong> Create hardware items and verify integrity (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a4d3d0942b4950223babe999aa818a369b64ac6f">a4d3d09</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.8...v2.14.9">2.14.9</a> (2021-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update corrects duplicated events (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/555c7fc78307c49666290ae28ed8e2d05883aacc">555c7fc</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.7...v2.14.8">2.14.8</a> (2021-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Architecture:</strong> update for page load speed (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5739ead27bbe1c54586057066254f9b60352c50a">5739ead</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.6...v2.14.7">2.14.7</a> (2021-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EVA Readiness:</strong> updated icon with text (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a9272a77bb50eaaa804502bd466ca83791857558">a9272a7</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.5...v2.14.6">2.14.6</a> (2021-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/fddf23d41ed9bd178454a1523745765996027393">fddf23d</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.4...v2.14.5">2.14.5</a> (2021-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Date Control:</strong> allow HTML in label (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/fb3eaa160017202b75abd864a2402f4a22d61bba">fb3eaa1</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.3...v2.14.4">2.14.4</a> (2021-08-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware Creation:</strong> added default drawing Numbers and AsBuilts (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/cfe3378073b2ff83de4a44707b6bab55aa922e11">cfe3378</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.2...v2.14.3">2.14.3</a> (2021-08-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Event:</strong> updated logic behind editing active events only (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4a8108a2b081c9ca5b9c0267ceced01949555613">4a8108a</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.1...v2.14.2">2.14.2</a> (2021-08-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Emu:</strong> Fix &quot;Days Since Last Eva&quot; on EMU table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/98c52b7a85451bde8484673bb371aceec61e5c3d">98c52b7</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.14.0...v2.14.1">2.14.1</a> (2021-08-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU:</strong> Event data update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6ad7378578ed3c7dd0c44240be2da5c2cefbbba8">6ad7378</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.13.0...v2.14.0">2.14.0</a> (2021-08-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema Changes:</strong> update to API v4.0 (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d16191261bc1b55175c32c8ac00f1267eefdde5e">d161912</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.12.0...v2.13.0">2.13.0</a> (2021-08-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EventsManage:</strong> default EVA view all if no active events (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/06d2f5625e3b6c0d5851901f95eb066e40e7ef5f">06d2f56</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Reporting:</strong> added Annex 2 PDF export (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0a1512fb4212d6c36539551140626316507079da">0a1512f</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.11.0...v2.12.0">2.12.0</a> (2021-08-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>e2e:</strong> Skip LCVG for the time being. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5a68aaf1d4ae6a579af45d0b987d666fbcb1f098">5a68aaf</a>)</li>
<li><strong>Eva Readiness:</strong> standardized how we display this field (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/894dfa8de25184a454234acbc52c5fdb436a5976">894dfa8</a>)</li>
<li><strong>Index:</strong> updating cache headers (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1162979ca870b15ddb4314afc5fa1c76cbd5fee8">1162979</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Api:</strong> update consolidates properties and addes new ones (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d6e8a772908103059859a2e937b71939608438f6">d6e8a77</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.8...v2.11.0">2.11.0</a> (2021-08-17)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>lcvg:</strong> change value for lcvg wetted status column (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/97e5c5a994987793ff6eb72722405967193d7a69">97e5c5a</a>)</li>
<li><strong>Pending Maintenance:</strong> new Annex 2 preset and code refactoring (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bd860bc557094aeda4e4c095aacd21248cedea78">bd860bc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update database (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f74a743674a1377d9c2cbb9035e3808835f5883a">f74a743</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.7...v2.10.8">2.10.8</a> (2021-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>commentType:</strong> add back activity comment type (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/39bb4e6e3992e9d446b59254f2ae7d7df629951c">39bb4e6</a>)</li>
<li><strong>Hardware Table:</strong> reset archive flag on hardware type change (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7cbf2445fb4c40c64509248a6d88af79230052ff">7cbf244</a>)</li>
<li><strong>Service worker:</strong> removal of offline mode (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/07a30e1a0974909a003bb5191efcb311def3ea0a">07a30e1</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.6...v2.10.7">2.10.7</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> integrated pending maintenance table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ed9ee6942068253d61882f3a839d334e00b333bd">ed9ee69</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.5...v2.10.6">2.10.6</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity Manage:</strong> corrected edit popup (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/04fe1dd74b06a20ae4b086a3f5abfc1c9b7aff1c">04fe1dd</a>)</li>
<li><strong>Event Manage:</strong> added link to annex 2 report in the Increment modal (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4ae91223f3f7a18f25547bb330a6e59a2de2d6cc">4ae9122</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.4...v2.10.5">2.10.5</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events:</strong> cleanedup ReadonlyEva/Increment (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/20c9cfe8ef1d5736a8d214380a8340d90198d244">20c9cfe</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.3...v2.10.4">2.10.4</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> IEU Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5170a4fe0c0b1190f1bd7f36d966e352a4687472">5170a4f</a>)</li>
<li><strong>FEMUR:</strong> LPGT Battery Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/59421949cc549131fc9e3f6caf21ff5af93981d8">5942194</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.2...v2.10.3">2.10.3</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ehipLight:</strong> correct ehip light (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/16d25f8b36ccdba0832fdc3acbd4440e83638a0a">16d25f8</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.1...v2.10.2">2.10.2</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> LREBA Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4f7d65926efbb750802d03023ae4c7cdb1d208ee">4f7d659</a>)</li>
<li><strong>FEMUR:</strong> UIA Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/da0da2c00dcc0e4483c827a4a613c77f3317b20a">da0da2c</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.10.0...v2.10.1">2.10.1</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Transforms:</strong> update includes addStatusDateDisplays (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ccbae60e4e95793a8666add71b1e066781a7f1cb">ccbae60</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.7...v2.10.0">2.10.0</a> (2021-08-16)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> SCOF Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0204d22996a3df7c0d50e1b972bc97f25e518d5d">0204d22</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Testing:</strong> Add End-to-End testing (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d1177a3426861dc5c8e37b82ff8b5e9a3c7bb5f1">d1177a3</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.6...v2.9.7">2.9.7</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> LCVG Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f14e908df8c202faca706c4774339e76c225dbf2">f14e908</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.5...v2.9.6">2.9.6</a> (2021-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> HUT ORU Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a639dd074e7bd62610b02d0f2710f1e36eacc7b9">a639dd0</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.4...v2.9.5">2.9.5</a> (2021-08-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> updates (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/13cb26658f1b92d790c95a6d781b7d9f69e6a02a">13cb266</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.3...v2.9.4">2.9.4</a> (2021-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Emu Battery LLB:</strong> removing READONLY status (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6045f4890859ad6fd37dbadc592db43b4b32d4a6">6045f48</a>)</li>
<li><strong>Eva:</strong> hide endDate and make it equal to startDate (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c10c2424233d6596580c8f7b3784dd426e3a11c4">c10c242</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.2...v2.9.3">2.9.3</a> (2021-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f4cd180d601c646f2d7785c067ef7af34b0f471f">f4cd180</a>)</li>
<li><strong>SideNav:</strong> order menu alphabetically (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ade2bd50b195f265a749f8854c8489a428b49aa8">ade2bd5</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.1...v2.9.2">2.9.2</a> (2021-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> UIA Biocide Filter Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e267b80e3bb1af99d64c8815d53235432304d0e2">e267b80</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.9.0...v2.9.1">2.9.1</a> (2021-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> HUT ORU Scrubber Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f5a622d04d9fd352db4c4c71aa65a040fb9d6cb0">f5a622d</a>)</li>
<li><strong>FEMUR:</strong> PGT Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b1a460ab969a70b10dd3867363c177523ded60be">b1a460a</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.8.3...v2.9.0">2.9.0</a> (2021-08-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> EMU Feedwater Supply Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9e917d6b7924f9728ebb3b0de77eca9a52da83e9">9e917d6</a>)</li>
<li><strong>FEMUR:</strong> SAFER Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7462535872b9a7ada95787aa9912152b4e2b4ec1">7462535</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Annex 2:</strong> new report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f62d2d23375b44290b326d9fcb4ad3f2e50b6825">f62d2d2</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.8.2...v2.8.3">2.8.3</a> (2021-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> EMU Water Processing Jumper Table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/52d38740793a7e1f338904a4a260a50e35bc5be7">52d3874</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.8.1...v2.8.2">2.8.2</a> (2021-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> EMU Tables (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/85a8ac67178ec409269592d796a9e31aaacdf243">85a8ac6</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.8.0...v2.8.1">2.8.1</a> (2021-08-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>SideNav:</strong> allow side nav to stay open, remain open after selecting hardware. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/13f61aba36f0b56a2107ce935bc7f08896d3bfd3">13f61ab</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.7.5...v2.8.0">2.8.0</a> (2021-08-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>EHIP_LIGHT:</strong> scaffold ehip light hardware type (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/357a6f569c5ca05e855c3e58f63c5ceac7bc8df5">357a6f5</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.7.4...v2.7.5">2.7.5</a> (2021-08-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>LREBA and LPGT:</strong> forms UI (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bcc7af559179180981b5eb0fb0dba77747d22e37">bcc7af5</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.7.3...v2.7.4">2.7.4</a> (2021-08-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> updated Metox Simulator table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/97ea95ebd1a553f6fed0c0077fdb35220fe114e2">97ea95e</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.7.2...v2.7.3">2.7.3</a> (2021-08-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR:</strong> EMU Battery LLB table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/85d03b555dfc5788691e19ebcb87f3fcbf1a5c01">85d03b5</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.7.1...v2.7.2">2.7.2</a> (2021-08-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EHIPLIGHT:</strong> adding type (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6af833bf6d31c51b23ed99ffa2930bd15e0876e0">6af833b</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.7.0...v2.7.1">2.7.1</a> (2021-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR report:</strong> Biocide Filter table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/abd9f275cd22fc53c1310b923403209a2ba0518c">abd9f27</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.6.3...v2.7.0">2.7.0</a> (2021-08-11)</h1>
<h3>Features</h3>
<ul>
<li><strong>AppTime:</strong> time input control (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0b5c0501ed2674fb7e0a37d97021af6d5fd705b2">0b5c050</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.6.2...v2.6.3">2.6.3</a> (2021-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FEMUR Metox:</strong> Metox table UI (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1d114db0e041c3c3af7f23582709bd4cd524f22e">1d114db</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.6.1...v2.6.2">2.6.2</a> (2021-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Display ReadOnly Attributes:</strong> UX of these labels updated (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9a5b8c13737db851fbf08d38757d43dbc795b4f9">9a5b8c1</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.6.0...v2.6.1">2.6.1</a> (2021-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0291dec7b62bb9ad8095f56bf032e74eb12456a9">0291dec</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.5.0...v2.6.0">2.6.0</a> (2021-08-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>FEMUR:</strong> fetch all Item Instances for tables to consume (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b6572938ffe30c7344a5346352ecd83e4885b760">b657293</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.4.3...v2.5.0">2.5.0</a> (2021-08-09)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events Manage:</strong> update includes displaying of events (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/69a62f6f6b525586ead3fdafd4caa99fa5158abd">69a62f6</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Reporting:</strong> added Flight Hardware report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b436fa75c01691f0c159cbc283defddd0c4b3115">b436fa7</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.4.2...v2.4.3">2.4.3</a> (2021-08-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UX:</strong> cleanup (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0d7c75f8ecfc03d702cd2617a2c88503b4566172">0d7c75f</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.4.1...v2.4.2">2.4.2</a> (2021-08-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> Create remaining form definitions (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d0394b13367275313827640f4ea59edb278e2603">d0394b1</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.4.0...v2.4.1">2.4.1</a> (2021-08-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Flights Dropdown:</strong> improved UX (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/51aef905fb95ac5411b02dd84495acf2bd006cf9">51aef90</a>)</li>
<li><strong>Hardware location:</strong> renaming to Current Location as that better describes what data goes there (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1c1ac3a54619380ae24bb2e916a9bdbbb385b3e4">1c1ac3a</a>)</li>
<li><strong>ReadOnlyActivity:</strong> add expandable table and attr data inside it (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/27bc379a28343ec16fb31de8f5f551534599fe60">27bc379</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.3.5...v2.4.0">2.4.0</a> (2021-08-05)</h1>
<h3>Features</h3>
<ul>
<li><strong>Events:</strong> Read Only Event details (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5accbc6cf1da751d0b5c7d83eb7e006fe77c2da6">5accbc6</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.3.4...v2.3.5">2.3.5</a> (2021-08-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EMU data:</strong> filling out missing EMU data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/77b15763ed9c324f3986ee8eb49456854ac4d2ae">77b1576</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.3.3...v2.3.4">2.3.4</a> (2021-08-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Eva Modal:</strong> corrected issue where the modal would show incorrectly (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/eaa41415273406813792f9186ed04db455d93f80">eaa4141</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.3.2...v2.3.3">2.3.3</a> (2021-08-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>WhoAmI:</strong> initialize view (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ab350c5ccf6dab2b3861b366f21e61fd07b0361d">ab350c5</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.3.1...v2.3.2">2.3.2</a> (2021-08-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Date Input:</strong> updated validation for showing required message. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b5346c994767655f8f896fc1e24e8f78add1bd1c">b5346c9</a>)</li>
<li><strong>EMU Micron Filter:</strong> add table headers, transform, etc (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4c06b4b293a71245fc8282ff83453f835632f443">4c06b4b</a>)</li>
<li><strong>Schema update:</strong> adds computedIncrements (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9a40a2bd4703c719ba685f2bd45c2022cd845cae">9a40a2b</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.3.0...v2.3.1">2.3.1</a> (2021-08-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UX:</strong> update includes many UX consistency updates (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0a9233b3125e466f6a7f272601d81a4e2c0a5a7a">0a9233b</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.2.0...v2.3.0">2.3.0</a> (2021-08-04)</h1>
<h3>Features</h3>
<ul>
<li><strong>Flights:</strong> create/edit/delete flights (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c74ae705c67b31fbdfb2ab970597581ae46e850d">c74ae70</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.1.0...v2.2.0">2.2.0</a> (2021-08-04)</h1>
<h3>Features</h3>
<ul>
<li><strong>IncrementEvents:</strong> create and implement IncrementEvents report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/41da3b6182926dc9fcdd353f9d859a0003dd8e0a">41da3b6</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.0.1...v2.1.0">2.1.0</a> (2021-08-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EVA:</strong> update corrects a UX issue (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6a02aa9e624e41d4b8424680a66940b31b6374e4">6a02aa9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>EVAs:</strong> create, edit, and delete EVAs. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a574f2fd2f05a3827035efb2ed3670d1a5bb45f4">a574f2f</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v2.0.0...v2.0.1">2.0.1</a> (2021-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/45002675359890b9b226f4e2de661c40644c6f29">4500267</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.15.2...v2.0.0">2.0.0</a> (2021-08-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activities:</strong> Create/Manage UX Redesign (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bb1f8416d467902487896ff2f3f98353f2e50da8">bb1f841</a>)</li>
<li><strong>Actvities:</strong> Add read-only view for activities (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/36bc9f0aee6c12ffe162b56ef5808c1ee331f924">36bc9f0</a>)</li>
<li><strong>Increment:</strong> creation, edit and delete functionality (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a00c415bac201e6229ce4711c493343ee5b6ddfb">a00c415</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Activities:</strong> this updates the basic functionality of OOT</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.15.1...v1.15.2">1.15.2</a> (2021-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comment:</strong> remove Event as an option for comment modal (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/2c0a006b0c05cd7e58bdc3874e1dd641a6fed01b">2c0a006</a>)</li>
<li><strong>Read Only Item Instance:</strong> display attributes correctly (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5060100f4a6f6d5c2b74ec6f187e593212ece1a7">5060100</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.15.0...v1.15.1">1.15.1</a> (2021-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Navbar:</strong> add read-only tag to routes that are read only (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/44c4608e12f0eaaf87819749c460e8dff6131c15">44c4608</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.14.3...v1.15.0">1.15.0</a> (2021-08-03)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>addDisplayDates:</strong> fix minor addDateDisplays error (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/072afcb4553bea7292aa14b8517a946dbcb0aed0">072afcb</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>ButtonReadOnly:</strong> create read only button, implement on hardware manage view (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/65428daf571a36dd1148136bb4ed5e6398da3be6">65428da</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.14.2...v1.14.3">1.14.3</a> (2021-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to new version (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f4e8f7f189e0f60f4615f29e4af924db8ab0ba95">f4e8f7f</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.14.1...v1.14.2">1.14.2</a> (2021-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware:</strong> consolidate a single IHardware interface (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9e4b5f66d5d01150627802ac1269490fd0b97ebd">9e4b5f6</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.14.0...v1.14.1">1.14.1</a> (2021-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EmuIon:</strong> add transforms and table columns etc (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9ed1e3c7a79913613d5079815620e986f7a995ae">9ed1e3c</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.13.1...v1.14.0">1.14.0</a> (2021-08-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>Events Manage:</strong> initial ui to show EVAs, Flights, Increments (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/747d9ad9a979a75b311288830cf77cc2a93ff87c">747d9ad</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.13.0...v1.13.1">1.13.1</a> (2021-08-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Create Hardware:</strong> updated info for flights up and down. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/355286b2e364e43f5913cf87e33ff46e145b2d24">355286b</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.6...v1.13.0">1.13.0</a> (2021-08-02)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Header:</strong> allowing html in header for subscripts. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c058abc16841f3a8ac6db74809e9822a9af13299">c058abc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Hardware:</strong> add archive/unarchive button to manage page (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/dfacb171c4ab1c7875d5f7accc8a2bfec3ebf00c">dfacb17</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.5...v1.12.6">1.12.6</a> (2021-07-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware Comments:</strong> Filtering out Activity comments on hardware comments table. (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4360cce1a09dd6b43174d0e8b12df9e8c0fc7515">4360cce</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.4...v1.12.5">1.12.5</a> (2021-07-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Automated Testing:</strong> introduction to automated testing end-to-end (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4df12add58dd67983c9ae077dd047b36322ffe40">4df12ad</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.3...v1.12.4">1.12.4</a> (2021-07-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Pending Maint:</strong> update to remove panel (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/fd4beb3c222ca470a3d9936be819a18384a162d2">fd4beb3</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.2...v1.12.3">1.12.3</a> (2021-07-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>SAFER:</strong> added missing data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/e49c51f6620e27d228d92779e65879a79c5f1b72">e49c51f</a>)</li>
<li><strong>Side Nav:</strong> showing the users the selected route (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5ee168ad18d17bb6e7bf77f7c698b5c27a3e954f">5ee168a</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.1...v1.12.2">1.12.2</a> (2021-07-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Emu Battery:</strong> update table definitions (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/75aef251c8dd051ba3c0bad016d95fc322862ca7">75aef25</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.12.0...v1.12.1">1.12.1</a> (2021-07-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Side navigation:</strong> update to include events and toggle hardware types (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/562f70e05316f2470ee42d40f3cfa10842789eee">562f70e</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.11.2...v1.12.0">1.12.0</a> (2021-07-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activity Manage:</strong> initialize page for managing activity details (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7f236fb2f0802da8bcb8a7977d06171ad7b7887d">7f236fb</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.11.1...v1.11.2">1.11.2</a> (2021-07-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity Creation:</strong> updated ux (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/de82a598396307df486e9bd7684cb0311ac09954">de82a59</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.11.0...v1.11.1">1.11.1</a> (2021-07-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>METOX:</strong> updated status column (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/2002216e1732186004d0d17bcb76f82ae66a66c7">2002216</a>)</li>
<li><strong>ReadOnly Item Instance:</strong> update ui (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d3f36be7ae33e6808eb432d7ac9d925229052e28">d3f36be</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.10.0...v1.11.0">1.11.0</a> (2021-07-23)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activity:</strong> Create activities and update activity hardware instances (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/9ff43fa85a24a97955d28c2171cd51b7b25370f5">9ff43fa</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.9.2...v1.10.0">1.10.0</a> (2021-07-23)</h1>
<h3>Features</h3>
<ul>
<li><strong>ReadOnlyItemInstance:</strong> implement checks for readonly (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0a71f84eb6f3cdba025b44241d702220ac010bf5">0a71f84</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.9.1...v1.9.2">1.9.2</a> (2021-07-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>LREBA:</strong> fixed missing data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c6efd6e3a53f13a2af2caf3cc7e9777920bcf639">c6efd6e</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.9.0...v1.9.1">1.9.1</a> (2021-07-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>METOX Simulator:</strong> fixed missing data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/05f50887a150d86d396ad449d25c8edd56ac1707">05f5088</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.8.0...v1.9.0">1.9.0</a> (2021-07-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activity Report:</strong> new report for viewing activities (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c78ba66c88c9ea77b1e535b3164917735423f5d9">c78ba66</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.7.0...v1.8.0">1.8.0</a> (2021-07-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Safety Tethers:</strong> fixed missing status icons (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ea2aaf664b5bf1486e4fa7a592dfee1ff3c41e18">ea2aaf6</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Activity:</strong> Create modal for adding activity on Hardware Manage page (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b512b216b55f9c00e49883d3f11712b137263986">b512b21</a>)</li>
<li><strong>Reporting:</strong> Item Instances report (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/cb4cf5153362bbf02a3a5b0d06b864f476be9b2b">cb4cf51</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.6.0...v1.7.0">1.7.0</a> (2021-07-21)</h1>
<h3>Features</h3>
<ul>
<li><strong>readOnlyActivity:</strong> add readOnlyActivity, also add UIA hardware type and scaffolding (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/0ff286fec444fb540ab87194197a9676e1b5bb78">0ff286f</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.5.5...v1.6.0">1.6.0</a> (2021-07-21)</h1>
<h3>Features</h3>
<ul>
<li><strong>Activities:</strong> Add a Create New Activity view (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/ffb9da4e09323b7c4171c143941cabd5c2293279">ffb9da4</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.5.4...v1.5.5">1.5.5</a> (2021-07-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readme:</strong> update readme (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/49795afd83ed6087875298e56d8fdf279b0c9669">49795af</a>)</li>
<li><strong>scrollbar:</strong> fix double scrollbar issue (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d2a866ee28e778e3f907e47bccb9e9b9c48ea4b7">d2a866e</a>)</li>
<li><strong>UI:</strong> dropdown lists now support subscript and other HTML (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/8033427026b548643582808265f6c22ebe1b4c16">8033427</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.5.3...v1.5.4">1.5.4</a> (2021-07-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ActivityTypeToHardwareTypes:</strong> mapping data (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/60d726ce3c3ad2881558fddfe13c46c391cb2f99">60d726c</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.5.2...v1.5.3">1.5.3</a> (2021-07-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> fixed an issue where the page was wider than the viewport (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/604c475e5b1172e4c6df284ed4077dc667d62e63">604c475</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.5.1...v1.5.2">1.5.2</a> (2021-07-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>HardwareTypes to ActivityType:</strong> mapping first pass (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/dcff1fa5c4f3ac383da3113db2abcbf0b2e86bd7">dcff1fa</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.5.0...v1.5.1">1.5.1</a> (2021-07-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity to Hardware Types:</strong> initializing the table headers (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/cb349ec77c9520f63863845da10198ad277a452d">cb349ec</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.4.0...v1.5.0">1.5.0</a> (2021-07-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware Create/Edit Forms:</strong> enables creation and edit of all hardware types (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/7080be56f74e1e4414048968b3e345b9e011ee36">7080be5</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.3.0...v1.4.0">1.4.0</a> (2021-06-29)</h1>
<h3>Features</h3>
<ul>
<li><strong>EMU Battery LLB:</strong> Add create and edit form (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/86d757c8bd727cd9d9d835e904c13955373c7936">86d757c</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.2.0...v1.3.0">1.3.0</a> (2021-06-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware/EMU:</strong> Display updated item list after creating entity (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d7f1f4512a3f5ffdd006832d1c365917336c97d4">d7f1f45</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.1.5...v1.2.0">1.2.0</a> (2021-06-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>EMU:</strong> Add create and edit EMU modals (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/bdc9c60d98695cc4884578f155911f3df3380e25">bdc9c60</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.1.4...v1.1.5">1.1.5</a> (2021-06-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>API:</strong> update to correct changes to the api (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/507f2d02cd2afb110b3f2fe03c69118762cc1e75">507f2d0</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.1.3...v1.1.4">1.1.4</a> (2021-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Emu Manage:</strong> update table defs (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/181a4ed488039ff6d8518d457dafe9e759b36437">181a4ed</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.1.2...v1.1.3">1.1.3</a> (2021-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity:</strong> init create (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/44b3e5e7e4148507a1391cc83496e90baa4fe693">44b3e5e</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.1.1...v1.1.2">1.1.2</a> (2021-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> initing reports (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/88858e48f66a49a75df036f57a77bb06d28fd2fe">88858e4</a>)</li>
</ul>
<h2><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.1.0...v1.1.1">1.1.1</a> (2021-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> init splash page (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/885666adbd2e7cb5cb6e79bff01d314d7d3047d1">885666a</a>)</li>
</ul>
<h1><a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/compare/v1.0.0...v1.1.0">1.1.0</a> (2021-06-24)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>adding Controls:</strong> COSMIC controls (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/122b6f0a5cdb11a5b57f2d1a47b526cf5ea57a8c">122b6f0</a>)</li>
<li><strong>App Modals:</strong> refactor app modals for proper closing (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/74bbba143950de58415b5f6e8a46ebca3bbecc7a">74bbba1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Comments:</strong> ability to create comments (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/1905b1550f427696b37df67e612d3f7d7ee1a740">1905b15</a>)</li>
<li><strong>Component:</strong> renaming components (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d84684bcf223056b6180c59590af72d5682a2a7c">d84684b</a>)</li>
</ul>
<h1>1.0.0 (2021-06-23)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Api:</strong> update api route (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f78827e34238921c2b1bc0d941993d96d2b94884">f78827e</a>)</li>
<li><strong>appdat:</strong> fixed ci config (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/06d1282130d2c4dc2b1896f72f3b3d0efc2af9be">06d1282</a>)</li>
<li><strong>appdat:</strong> fixing ci to work with local npm packages (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/cd1305ba86cb2333ec885d5aeafd38601c0ba7c1">cd1305b</a>)</li>
<li><strong>appdat:</strong> fixing ci to work with local npm packages (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/c874672d0aaf6f7c7efcfa3629513cc6d899580e">c874672</a>)</li>
<li><strong>appdat:</strong> updating internal port (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a961c9cb177c85feb1567602539747c2d93558b5">a961c9c</a>)</li>
<li><strong>ci:</strong> fixed issue with helm release names (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/d397fb40ff7e93ea6595f11b65fbf79c8dea3ddf">d397fb4</a>)</li>
<li><strong>Code cleanup:</strong> remove unused code (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/546d9af13cdec99c2bde80deff7cf1708a144c1d">546d9af</a>)</li>
<li><strong>Code cleanup:</strong> remove unused code (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/5b667b1418c805bfdf274c8767560aa08366592d">5b667b1</a>)</li>
<li><strong>EMU Table definitions:</strong> init (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/6fce639e3407dc9668eb1a6be43ae0acd8c4289a">6fce639</a>)</li>
<li><strong>footer:</strong> keep footer visible with scrollable content (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/4234fe68f8e965fe1b0ccef2e3bea4ffad6c845f">4234fe6</a>)</li>
<li><strong>Home:</strong> added Home page table (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/fcfa088b4843fe61b63dd14b68e91b8631355e75">fcfa088</a>)</li>
<li><strong>security:</strong> updating alipine packages to try and fix a few CVE findings (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/a0600fb225fb1449dcc1f03852e8ba923c12c239">a0600fb</a>)</li>
<li><strong>Table Headers:</strong> all table headers are defined (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f649d174106ec9b580310f18f802a411d0a4a390">f649d17</a>)</li>
<li><strong>table paging:</strong> check for server side paging when computing displayed rows (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/f6a93dd50e9de6238879044b986efde72ce97c0b">f6a93dd</a>)</li>
<li><strong>transforms:</strong> update (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/57f4ff1fad2e6748019fee492d293cb3d7592f41">57f4ff1</a>)</li>
<li><strong>UI:</strong> updated interaction (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b90ed8b6e45ed51180e83627a1de24ff5b053834">b90ed8b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Initial Commit:</strong> First repo commit (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/b8cef8e6ca67b543fb21d81365120cefae565049">b8cef8e</a>)</li>
<li><strong>Side navigation:</strong> complete side nav (<a href="https://appdat.jsc.nasa.gov/cosmic/oot/oot_web/commit/369968646aa4d228cddb7b648aa1403664d3b919">3699686</a>)</li>
</ul>
</section></template><script>export default {components:{}}</script>