var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FemurTable as FemurTableType, FemurTypeDisplay } from '~/types';
import { sortObjectBy, transformHardwaresToTableFormat } from '~/utils';
import { FemurTableHeadersByHardwareType } from '~/utils/constants/tableDefinitions';
let FemurTable = class FemurTable extends Vue {
    hardwareType;
    itemInstances;
    get tableItems() {
        return transformHardwaresToTableFormat(this.hardwareType, this.itemInstances).sort(sortObjectBy('_serial', true));
    }
    get tableHeaders() {
        return FemurTableHeadersByHardwareType.get(this.hardwareType);
    }
    get tableTitle() {
        return FemurTypeDisplay.get(this.hardwareType) || '';
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof FemurTableType !== "undefined" && FemurTableType) === "function" ? _a : Object)
], FemurTable.prototype, "hardwareType", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], FemurTable.prototype, "itemInstances", void 0);
FemurTable = __decorate([
    Component
], FemurTable);
export default FemurTable;
